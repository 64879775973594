import React, { useEffect, useRef } from "react";
import { animate, useInView } from "framer-motion";
import "./style.css"

export const Statistiques = () => {
  return (
    <div className="fond">
        <div className="mx-auto max-w-3xl px-4 py-20 md:py-24 w-full ">
        <h2 className="mb-8 text-center  text-base text-indigo-900 sm:text-5xl md:mb-16">
            NOS STATISTIQUES
        {/* <span className="text-indigo-500"> BEAUTIFUL LANDING PAGE</span> */}  
        </h2>

        <div className="flex flex-col items-center justify-center sm:flex-row">
            <Stat
            num={7000}
            suffix="+"
            subheading="Orateurs Formés"
            />
            <div className="h-[1px] w-12 bg-indigo-200 sm:h-12 sm:w-[1px] " />
            <Stat
            num={200}
            suffix="+"
            subheading="Promotions"
            />
            <div className="h-[1px] w-12 bg-indigo-200 sm:h-12 sm:w-[1px] " />
            <Stat
            num={5}
            suffix="+"
            subheading="Formations"
            />
            <div className="h-[1px] w-12 bg-indigo-200 sm:h-12 sm:w-[1px] " />
            <Stat
            num={12}
            suffix="+"
            subheading="Experts"
            />
        </div>
        </div>
    </div>
    
  );
};

const Stat = ({ num, suffix, decimals = 0, subheading }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (!isInView) return;

    animate(0, num, {
      duration: 2.5,
      onUpdate(value) {
        if (!ref.current) return;

        ref.current.textContent = value.toFixed(decimals);
      },
    });
  }, [num, decimals, isInView]);

  return (
    <div className="flex w-100 flex-col items-center py-8 sm:py-0 px-5">
      <p className="mb-2 text-center text-7xl font-semibold sm:text-6xl">
        <span ref={ref}></span>
        {suffix}
      </p>
      <p className="w-full text-center text-neutral-600 ">{subheading}</p>
    </div>
  );
};